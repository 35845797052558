<template>
  <div class="text-sm">
    <div v-if="address">
      <h5 class="mb-3">
        {{ title || "Address" }}
      </h5>
      <address>
        <p class="font-weight-bold mb-1">
          {{ address.given_name }} {{ address.family_name }}
        </p>
        <p class="mb-1">
          {{ address.address_line_1 }}<br />
          {{ address.postal_code }} {{ address.locality }}
          <span v-if="address.country"
            ><br />
            {{ address.country.code }}
          </span>
        </p>
      </address>
    </div>
    <warning-display v-else>
      <svg
        class="fill-current w-4 h-4 mr-1"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
      >
        <path
          fill-rule="evenodd"
          d="M10 20s7-9.134 7-13A7 7 0 003 7c0 3.866 7 13 7 13zm0-11a2 2 0 100-4 2 2 0 000 4z"
        />
      </svg>
      If you wish to add an address, update your profile.
    </warning-display>
  </div>
</template>

<script>
import WarningDisplay from "@/components/UI/WarningDisplay.vue";

export default {
  components: { WarningDisplay },
  props: {
    title: {
      required: true,
    },
    address: {
      required: true,
    },
  },
};
</script>
