<template>
  <div class="row fadeInUp" role="main">
    <div class="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
      <h5>Preview</h5>
      <div class="card border-0 shadow-sm mb-4">
        <div class="card-body">
          <div class="align-items-center text-center mb-10 row">
            <div class="text-sm-left col-sm-6">
              <h1>LOGO</h1>
            </div>
            <div class="text-sm-right mt-3 mt-sm-0 col">
              <h2 class="mb-3">Invoice</h2>
              TODO
              <h5>Falcon Design Studio</h5>
              <p class="fs--1 mb-0">
                156 University Ave, Toronto<br />On, Canada, M5H 2H7
              </p>
            </div>
          </div>
          <div class="justify-content-between align-items-center row">
            <div class="col">
              <address-display
                :title="'Billing address'"
                :address="order.billing_address"
              ></address-display>
            </div>
            <div class="ml-auto col-sm-auto">
              <div class="table-responsive">
                <table class="fs--1 table table-sm table-borderless">
                  <tbody>
                    <tr>
                      <th class="text-sm-right">Invoice No:</th>
                      <td>{{ "INV-" + order.order_number }}</td>
                    </tr>
                    <tr>
                      <th class="text-sm-right">Order Number:</th>
                      <td>{{ order.order_number }}</td>
                    </tr>
                    <tr>
                      <th class="text-sm-right">Invoice Date:</th>
                      <td>{{ order.created_at | formatDate }}</td>
                    </tr>
                    <tr>
                      <th class="text-sm-right">Payment Due:</th>
                      <td>Upon receipt</td>
                    </tr>
                    <tr class="alert-success font-weight-bold">
                      <th class="text-sm-right">Amount Due:</th>
                      <td>
                        <money-format
                          class="inline"
                          :value="order.total / 100"
                          :locale="'en'"
                          :currency-code="order.currency"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="table-responsive mt-4 fs--1">
            <table class="table table-striped border-bottom">
              <thead class="bg-primary text-white">
                <tr>
                  <th class="border-0">Products</th>
                  <th class="border-0 text-center">Quantity</th>
                  <th class="border-0 text-right">Rate</th>
                  <th class="border-0 text-right">Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in order.order_items" :key="item.id">
                  <td class="align-middle">
                    <h6 class="mb-0 text-nowrap">#{{ item.sku }}</h6>
                    <p class="mb-0">{{ item.name }}</p>
                  </td>
                  <td class="align-middle text-center">
                    {{ item.qty_ordered }}
                  </td>
                  <td class="align-middle text-right">
                    <money-format
                      class="inline"
                      :value="item.price / 100"
                      :locale="'en'"
                      :currency-code="order.currency"
                    />
                  </td>
                  <td class="align-middle text-right">
                    <money-format
                      class="inline"
                      :value="item.total / 100"
                      :locale="'en'"
                      :currency-code="order.currency"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row no-gutters justify-content-end">
            <div class="col-auto">
              <table class="table table-sm table-borderless text-right">
                <tbody>
                  <tr>
                    <th class="text-900">Subtotal:</th>
                    <td class="font-weight-semi-bold">
                      <money-format
                        class="inline"
                        :value="(order.total - order.total_tax) / 100"
                        :locale="'en'"
                        :currency-code="order.currency"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th class="text-900">Tax:</th>
                    <td class="font-weight-semi-bold">
                      <money-format
                        class="inline"
                        :value="order.total_tax / 100"
                        :locale="'en'"
                        :currency-code="order.currency"
                      />
                    </td>
                  </tr>
                  <tr class="border-top">
                    <th class="text-900">Total:</th>
                    <td class="font-weight-semi-bold">
                      <money-format
                        class="inline"
                        :value="order.total / 100"
                        :locale="'en'"
                        :currency-code="order.currency"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="bg-light card-footer">
          <p class="fs--1 mb-0">
            <strong>Notes: </strong>We really appreciate your business and if
            there’s anything else we can do, please let us know!
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jsonAPI from "@/api";
import MoneyFormat from "vue-money-format";
import AddressDisplay from "@/components/UI/AddressDisplay";

export default {
  name: "OrderView",
  components: { AddressDisplay, MoneyFormat },
  beforeRouteEnter(to, from, next) {
    const _id = to.params.id;
    jsonAPI.get("order/" + _id).then((response) => {
      next((vm) => {
        vm.order = response.data;
      });
    });
  },
  data() {
    return {
      order: {},
      showShipping: false,
    };
  },
};
</script>
