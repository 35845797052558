<template>
  <div
    class="bg-yellow-200 text-yellow-600 rounded border border-yellow-300 text-sm my-2 px-4 py-2 flex items-center"
  >
    <slot />
  </div>
</template>

<script>
export default {};
</script>
